<template>
<!-- Knowledge base category card 1-->
<a :class="disabled + ' card lift lift-sm h-100 cursor-pointer'">
    <div class="card-body" @click="redirectAltaRegistro">
        <h5 :class="'card-title mb-2 '+ colorTitle">
            <i class="far fa-file"></i>
            {{title}}
        </h5>
        <p class="card-text mb-1">{{desc}}</p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-6 col-xs-6 col-sm-12 col-md-6" v-if="this.disabled == null">
                <div class="small text-muted text-start" v-if="this.informacion_modal == null || this.informacion_modal.length>1"><span style="color:blue;cursor:pointer;" @click="informacion">Informaci&oacute;n</span></div>
            </div>
            <div class="col-6 col-xs-6 col-sm-12 col-md-6">
                <div class="small text-muted text-end"><span style="color:#002f87 !important;cursor:pointer;" v-if="this.disabled == null" @click="redirectAltaRegistro">Acceder</span></div>
            </div>
        </div>
    </div>
</a>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

.card-body {
    margin-top: 30px;
}

.cursor-pointer {
    cursor: pointer;
}

.disabled {
    /* background: #e5e5e5; */
    box-shadow: none !important;
    cursor: inherit !important;
    opacity: .7;
    transform: none !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Vue from "vue"; // Vue router instance

export default {
    name:"cardTramite",
    components: {},
    mounted: function () {
    },
    methods: {
        redirectAltaRegistro() {
            if(!(this.disabled != null)){
                this.$store.commit("tramiteGenerico/SET_ICONTRAMITE", this.getIcon());
                this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADO", this.title);
                this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADODESC", this.desc);
                this.$store.dispatch("tramiteGenerico/getTramiteEstados", this.getDataTramite());
            }
        },
        getIcon(){
            if(this.tipo_tramite == 'gestion'){
                return "fas fa-folder-plus";
            }else{
                return "fas fa-search";
            }
        },
        getDataTramite() {
            let obj = new Object;
            obj.deft_tipo_tramite_id = this.defTramiteID;
            obj.cart_usuario_id = this.user;
            obj.url = this.url;
            obj.cart_empresa_id = this.$store.state.user.user.empresa.id
            return obj;
        },
        informacion() {
            if (this.disabled == null) {
                Vue.swal(this.title, this.informacion_modal, "question");
            }
        }
    },
    computed: {
        user: {
            get() {
                return this.$store.state.user.user.id;
            },
        },
    },
    props: {
        colorTitle: String,
        title: String,
        desc: String,
        disabled: String,
        url: String,
        informacion_modal: String,
        defTramiteID: Number,
        tipo_tramite:String

    }
}
</script>
